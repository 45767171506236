import { fetchQuery_DEPRECATED as fetchQuery } from 'react-relay/legacy';
import { Environment } from 'relay-runtime';
import {
    hasValidUserSessionGeoInfo,
    hasRegionsCookieValue,
    setUserGeoInfo,
    hasPriceBookNameCookieValue,
    hasRegionsListCookieValue,
    hasUserInEuropeValue,
} from './regionalInfoHelpers';
import { userGeoInfoQuery } from './userGeoInfoQuery';
import { userGeoInfoQuery as GeoInfoQueryType } from './__generated__/userGeoInfoQuery.graphql';

type SetRegionalInfoArgs = {
    relayEnv: Environment;
    cookieDomain: string;
    userId: string;
    useLocalStorage?: boolean;
    userCountryCode?: string | null;
};
const forceSetUserRegionalInfo = async ({
    relayEnv,
    cookieDomain,
    userId,
    useLocalStorage,
    userCountryCode,
}: SetRegionalInfoArgs): Promise<void> => {
    const data = await fetchQuery<GeoInfoQueryType>(relayEnv, userGeoInfoQuery, {
        cookieDomain,
        userId,
        userCountryCode,
    });

    let userGeoInfo;
    if (typeof data !== 'undefined') {
        userGeoInfo = data.viewer.regionalInfo?.[0];
    }
    setUserGeoInfo({ userGeoInfo, environment: relayEnv, useLocalStorage });
};

const setUserRegionalInfo = async ({
    relayEnv,
    cookieDomain,
    userId,
    useLocalStorage,
    userCountryCode,
}: SetRegionalInfoArgs): Promise<void> => {
    if (
        !hasValidUserSessionGeoInfo() ||
        !hasRegionsCookieValue() ||
        !hasPriceBookNameCookieValue() ||
        !hasUserInEuropeValue() ||
        !hasRegionsListCookieValue()
    ) {
        await forceSetUserRegionalInfo({
            relayEnv,
            cookieDomain:
                !hasRegionsCookieValue() || !hasPriceBookNameCookieValue() ? cookieDomain : '',
            userId,
            useLocalStorage,
            userCountryCode,
        });
    }
};

export { setUserRegionalInfo, forceSetUserRegionalInfo };
