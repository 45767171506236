type StartChatArgs =
    | {
          userId: string;
          orderId: string;
          firstName: string | null;
          lastName: string | null;
          email: string | null;
          selectedTopic: string;
          itemPk: string | null;
          sellerId: string;
          caseNumber?: string;
          isCheckout?: false;
      }
    | {
          userId: string | null;
          orderId: string | null;
          firstName: string | null;
          lastName: string | null;
          email: string | null;
          selectedTopic?: undefined;
          itemPk: string;
          sellerId?: undefined;
          caseNumber?: undefined;
          isCheckout: true;
      };

export const startChat = ({
    userId,
    orderId,
    firstName = '',
    lastName = '',
    email = '',
    selectedTopic,
    itemPk = '',
    sellerId,
    caseNumber = '',
    isCheckout = false,
}: StartChatArgs): void => {
    window.embedded_svc.liveAgentAPI.startChat({
        extraPrechatInfo: [
            {
                entityName: 'Contact',
                linkToEntityName: 'Case',
                linkToEntityField: 'ContactId',
                saveToTranscript: 'ContactId',
                showOnCreate: true,
                entityFieldMaps: [
                    {
                        doCreate: false,
                        doFind: true,
                        fieldName: sellerId ? 'Seller_ID__c' : 'X1stdibs_com_User_ID__c',
                        isExactMatch: true,
                        label: 'User Id',
                    },
                ],
            },
            {
                entityName: 'Order__c',
                saveToTranscript: 'Order__c',
                showOnCreate: true,
                entityFieldMaps: [
                    {
                        doCreate: false,
                        doFind: true,
                        fieldName: 'Order_ID__c',
                        isExactMatch: true,
                        label: 'Order Id',
                    },
                ],
            },
            {
                entityName: 'Case',
                linkToEntityField: 'CaseId',
                saveToTranscript: 'CaseId',
                showOnCreate: true,
                entityFieldMaps: [
                    {
                        doCreate: false,
                        doFind: true,
                        fieldName: 'CaseNumber',
                        isExactMatch: true,
                        label: 'Case Number',
                    },
                ],
            },
        ],
        extraPrechatFormDetails: [
            {
                label: 'First Name',
                name: 'FirstName',
                value: firstName,
                displayToAgent: true,
            },
            {
                label: 'Last Name',
                value: lastName,
                displayToAgent: true,
            },
            {
                label: 'Email',
                value: email,
                displayToAgent: true,
            },
            {
                label: 'Order Id',
                value: orderId,
                displayToAgent: true,
            },
            {
                label: 'ItemPK',
                value: itemPk,
                transcriptFields: ['Item_PK__c'],
                displayToAgent: true,
            },
            {
                label: 'User Id',
                value: sellerId ? sellerId : userId,
                displayToAgent: false,
            },
            ...(isCheckout
                ? [
                      {
                          label: 'Page Type',
                          value: 'Checkout',
                          transcriptFields: ['Chat_Page_Type__c'],
                          displayToAgent: true,
                      },
                  ]
                : [
                      {
                          label: 'Topic',
                          value: selectedTopic,
                          transcriptFields: ['Topic__c'],
                          displayToAgent: true,
                      },
                      {
                          label: 'Case Number',
                          value: caseNumber,
                          displayToAgent: false,
                      },
                  ]),
        ],
    });
};
