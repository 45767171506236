const KEY_SESSION_PAGE_COUNT = 'sessionPageCount';
const ECOM_PRODUCT_IMPRESSION = 'productImpression';
const ECOM_PRODUCT_CLICK = 'productClick';
const ECOM_PRODUCT_DETAIL = 'productDetail';
const ECOM_PRODUCT_CART = 'addToCart';
const ECOM_PRODUCT_CHECKOUT = 'checkout';
const ECOM_PRODUCT_PROMO_IMPRESSION = 'promotionImpression';
const ECOM_PRODUCT_PROMO_CLICK = 'promoClick';
const ECOM_PRODUCT_PURCHASE = 'purchase';

const EVENT_PAGEVIEW = 'e_ga_pageview';
const EVENT_EVENT = 'e_ga_event';
const EVENT_FORM_ERROR = 'e_form_error';
const EVENT_SOCIAL = 'e_ga_social';
const EVENT_PERF_WEB_VITALS = 'e_ga_web_vitals';
const EVENT_ECOM_PRODUCT_IMPRESSION = 'ecommerce_productImpression';
const EVENT_ECOM_PRODUCT_CLICK = 'ecommerce_productClick';
const EVENT_ECOM_PRODUCT_DETAIL = 'ecommerce_productDetail';
const EVENT_ECOM_PRODUCT_CART = 'ecommerce_addToCart';
const EVENT_ECOM_PRODUCT_CHECKOUT = 'ecommerce_checkout';
const EVENT_ECOM_PRODUCT_PROMO_IMPRESSION = 'ecommerce_promotionImpression';
const EVENT_ECOM_PRODUCT_PROMO_CLICK = 'ecommerce_promoClick';
const EVENT_ECOM_PRODUCT_PURCHASE = 'ecommerce_purchase';

export const trackingConstants = {
    KEY_SESSION_PAGE_COUNT,
    ECOM_PRODUCT_IMPRESSION,
    ECOM_PRODUCT_CLICK,
    ECOM_PRODUCT_DETAIL,
    ECOM_PRODUCT_CART,
    ECOM_PRODUCT_CHECKOUT,
    ECOM_PRODUCT_PROMO_IMPRESSION,
    ECOM_PRODUCT_PROMO_CLICK,
    ECOM_PRODUCT_PURCHASE,
} as const;

export const eventTypeConstants = {
    EVENT_ECOM_PRODUCT_IMPRESSION,
    EVENT_ECOM_PRODUCT_CLICK,
    EVENT_ECOM_PRODUCT_DETAIL,
    EVENT_ECOM_PRODUCT_CART,
    EVENT_ECOM_PRODUCT_CHECKOUT,
    EVENT_ECOM_PRODUCT_PROMO_IMPRESSION,
    EVENT_ECOM_PRODUCT_PROMO_CLICK,
    EVENT_ECOM_PRODUCT_PURCHASE,
    EVENT_PAGEVIEW,
    EVENT_EVENT,
    EVENT_FORM_ERROR,
    EVENT_SOCIAL,
    EVENT_PERF_WEB_VITALS,
} as const;

//GA4 constants
const EVENT_DWELL_TIME = 'dwell_time';
const EVENT_AB_TESTING = 'ab_testing';
const EVENT_AUCTION_BID = 'auction_bid';
const EVENT_PAYMENT_INFO = 'add_payment_info';
const EVENT_WISHLIST = 'add_to_wishlist';
const EVENT_SELECT_ITEM = 'select_item';
const EVENT_VIEW_ITEM = 'view_item';
const EVENT_VIEW_ITEM_LIST = 'view_item_list';
const EVENT_CONTACT_DEALER = 'contact';
const EVENT_VIEW_PROMOTION = 'view_promotion';
const EVENT_SELECT_PROMOTION = 'select_promotion';
const EVENT_SIGN_UP = 'sign_up';
const EVENT_LOGIN = 'login';
const EVENT_SHARE = 'share';
const EVENT_PURCHASE = 'purchase';
const EVENT_PRODUCT_INTERACTION = 'product_interaction';
const EVENT_ADD_CART = 'add_to_cart';
const EVENT_SEARCH = 'search';
const EVENT_PROMO_INTERACTION = 'promo_interaction';
const EVENT_RESULT_INTERACTION = 'results_interaction';
const EVENT_ITEM_UPLOAD_INTERACTION = 'item_upload_interaction';
const EVENT_BULK_ITEM_UPLOAD = 'bulk_item_upload_interaction';
const EVENT_ITEM_UPLOAD_COMPLETE = 'item_upload_complete';
const EVENT_DASHBOARD_INTERACTION = 'dashboard_interaction';
const EVENT_LISTING_INTERACTION = 'listing_interaction';
const EVENT_CONTACT_1STDIBS = 'contact_1stdibs';
const EVENT_INTL_INTERACTION = 'intl_interaction';
const EVENT_CHECKOUT_INTERACTION = 'checkout_interaction';
const EVENT_NAVIGATION = 'navigation';
const EVENT_BACKGROUND_REMOVAL = 'background_removal';
const EVENT_SELLER_FACILITATED_SUPPORT = 'seller_facilitated_support';
const EVENT_BUYER_TAX_EXEMPTION = 'buyer_tax_exemption';
const EVENT_INFO_INTERACTION = 'info_interaction';
const EVENT_SELLER_LEGACY = 'seller_legacy';
const EVENT_RECOMMENDATIONS = 'recommendations';
const EVENT_SHIPPING_METHOD_SAVED = 'shipping saved';
const EVENT_SHIPPING_METHOD_VIEWED = 'shipping viewed';
const EVENT_MESSAGE_CENTER = 'message_center';
const EVENT_PRODUCT = 'product';
const EVENT_PRIVATE_OFFER = 'private offer';
const EVENT_MC_ACTIONS = 'message center actions';
const EVENT_MC = 'message center';
const EVENT_ORDER_INTERACTION = 'order_interaction';
const EVENT_RESULTS_PAGE_FACET_SELECTED = 'results_page_facet_selected';
const EVENT_RESULTS_PAGE_FACET_DESELECTED = 'results_page_facet_deselected';
const EVENT_SELLER_APPLICATION = 'seller_application';
const EVENT_DEALER_DASHBOARD = 'dealer dashboard';
const EVENT_DEALER_COLLECTIONS = 'dealer collections';

const TRIGGER_BID_CLICK = 'bid click';
const TRIGGER_BID_INCREASE = 'increase bid click';
const TRIGGER_PDP_PURCHASE = 'pdp-purchase';
const TRIGGER_MORE_FROM_DEALER = 'more from dealer module';
const TRIGGER_CART_PURCHASE = 'cart-purchase';
const TRIGGER_MY_ORDERS_ACCEPT_OFFER = 'my orders-accept offer';
const TRIGGER_INVOICE_PURCHASE = 'invoice-purchase';
const TRIGGER_EMAIL_MAKE_OFFER = 'email-make offer';
const TRIGGER_EMAIL_ACCEPT_OFFER = 'email-accept offer';
const TRIGGER_EMAIL_PURCHASE = 'email-purchase';
const TRIGGER_INCREASE_MAX_BID_CLICK = 'increase max bid click';
const TRIGGER_ITEM_SAVE = 'item save';
const TRIGGER_CART_ACCEPT_OFFER = 'cart-accept offer';
const TRIGGER_MESSAGE_CENTER = 'message center';

const TRIGGER_SHIPPING_METHOD_CLICK = 'shipping method button';

const INTERACTION_TYPE_DEALER_FAVORITING = 'dealer favoriting';
const INTERACTION_TYPE_ITEM_FAVORITING = 'item favoriting';
const INTERACTION_TYPE_AUCTION = 'auction';
const INTERACTION_TYPE_CONTACT_DEALER = 'contact dealer';
const INTERACTION_TYPE_SHIPPING_QUOTE = 'shipping quote';
const INTERACTION_TYPE_PDP_CHAT_BOX = 'pdp chat box';
const INTERACTION_TYPE_USER_REGISTRATION = 'user registration';
const INTERACTION_TYPE_USER_LOGIN = 'user login';
const INTERACTION_TYPE_METAMASK_WALLET_CONNECT = 'metamask wallet connect';
const INTERACTION_TYPE_NFT_AUCTION = 'nft auction';
const INTERACTION_TYPE_MAKE_OFFER = 'make offer';
const INTERACTION_TYPE_ACCEPT_OFFER = 'accept offer';
const INTERACTION_TYPE_COUNTER_OFFER = 'counter offer';
const INTERACTION_TYPE_ADD_TO_CART = 'add to cart';
const INTERACTION_TYPE_ITEM_WATCH = 'item watch';
const INTERACTION_TYPE_TRADE_APPLICATION = 'trade application';
const INTERACTION_TYPE_DEALER_APPLICATION = 'dealer application';
const INTERACTION_TYPE_FACET_SELECTION = 'results page facet selection';
const INTERACTION_TYPE_INTERNATIONAL = 'international';
const INTERACTION_TYPE_WATCH_ATTRIBUTE = 'watch attribute';
const INTERACTION_TYPE_PDP_SIDEBAR = 'pdp - sidebar';
const INTERACTION_TYPE_PDP_CHAT_BOX_SUGGESTION = 'pdp chat box - suggestion';
const INTERACTION_TYPE_PRICING_GUIDANCE_DISPLAYED = 'pricing section';
const INTERACTION_TYPE_LISTING_SAVED = 'listing saved';
const INTERACTION_TYPE_PDP = 'pdp';
const INTERACTION_TYPE_PDP_ATTRIBUTE = 'pdp attribute';
const INTERACTION_TYPE_CALL_DEALER = 'call dealer';
const INTERACTION_TYPE_CHECKOUT_SIDERAIL_INTERACTION = 'checkout siderail interaction';
const INTERACTION_TYPE_CHECKOUT_ABANDON_PAGE = 'abandon page modal';
const INTERACTION_TYPE_CHECKOUT_ABANDONED = 'abandoned checkout';
const INTERACTION_TYPE_CHECKOUT = 'checkout';
const INTERACTION_TYPE_ITEM_UPLOAD_PRIMARY_IMAGE = 'item upload - primary image';
const INTERACTION_TYPE_INFO_CLICK = 'info click';
const INTERACTION_TYPE_DASHBOARD = 'dashboard';
const INTERACTION_TYPE_SOCIAL_CONNECTION = 'social connection';
const INTERACTION_TYPE_USER_PREFERENCE_SURVEY = 'user preference survey';
const INTERACTION_TYPE_USER_TYPE_SELECTION = 'user type selection';
const INTERACTION_TYPE_GLOBAL_NAV = 'global nav';
const INTERACTION_TYPE_MESSAGE_BANNER = 'message banner';
const INTERACTION_TYPE_CONTACT_SUBMITTED = 'contact_submitted';
const INTERACTION_TYPE_SHIPPING_OPTIONS = 'shipping options';
const INTERACTION_RECOMMENDATION_VIEW_ALL_CLICKED = 'view all clicked';
const INTERACTION_TYPE_PDP_MORE_FROM_DEALER = 'pdp more from dealer';
const INTERACTION_TYPE_OBFUSCATED_TEXT_IN_MESSAGE = 'obfuscated text in message';
const INTERACTION_TYPE_DEALER_INFO_IN_MESSAGE = 'dealer info in message';
const INTERACTION_TYPE_SHIPPING = 'shipping step';
const INTERACTION_TYPE_MC = 'message center';
const INTERACTION_TYPE_MARKETING_OPTIN = 'marketing optin';
const INTERACTION_TYPE_SMS_OPTIN = 'sms optin';
const INTERACTION_TYPE_URGENCY = 'urgency';
const INTERACTION_TYPE_APP_DOWNLOAD = 'app download';
const INTERACTION_TYPE_ORDER_CANCELATION = 'order cancellation';
const INTERACTION_TYPE_PRICE_MATCH = 'price match';
const INTERACTION_TYPE_DESIGN_PROFILE = 'design profile';
const INTERACTION_TYPE_NOTIFICATIONS = 'notifications';
const INTERACTION_TYPE_ACTIONS_REQUIRED = 'actions required';
const INTERACTION_TYPE_ANALYTICS_MODULE = 'analytics module';
const INTERACTION_TYPE_EVERGREEN_LINK = 'evergreen link';
const INTERACTION_TYPE_NEWS_MODULE = 'news module';
const INTERACTION_TYPE_ORDER_FEEDBACK = 'order feedback';
const INTERACTION_TYPE_SEE_MARKETING_CALENDAR = 'marketing calendar clicked';
const INTERACTION_TYPE_BANNER_P1 = 'banner click';
const INTERACTION_TYPE_SELLER_DASHBOARD_OVERVIEW_BANNER =
    'dealer dashboard overview banner clicked';
const INTERACTION_TYPE_SELLER_DASHBOARD_VIEW_ALL_LISTINGS = 'view all link clicked';
const INTERACTION_TYPE_MY_ACCOUNT = 'my account';
const INTERACTION_TYPE_ORDER_SUMMARY_DRAWER = 'order summary drawer';
const INTERACTION_TYPE_SUPPORT = 'support';

const STEP_INTERACTION_DEALER_ADDED = 'dealer added';
const STEP_INTERACTION_ITEM_ADDED = 'item added';
const STEP_INTERACTION_ITEM_ADDED_TO_FOLDER = 'item added to folder';
const STEP_INTERACTION_ITEM_FOLDER_CLICKED = 'item favoriting folder clicked';
const STEP_INTERACTION_ITEM_REMOVED_FROM_FOLDER = 'item removed from folder';
const STEP_INTERACTION_ITEM_FOLDER_CREATED = 'item favoriting folder created';
const STEP_INTERACTION_ITEM_REMOVED = 'item removed';
const STEP_INTERACTION_DISPLAY = 'display';
const STEP_INTERACTION_CLICK = 'click';
const STEP_INTERACTION_SUCCESS = 'success';
const STEP_INTERACTION_FAIL = 'fail';
const STEP_INTERACTION_EXPAND = 'expand';
const STEP_INTERACTION_COLLAPSE = 'collapse';
const STEP_INTERACTION_ENTRY = 'entry';
const STEP_INTERACTION_CONFIRM_BID_CLICK = 'confirm bid click';
const STEP_INTERACTION_COMPLETE = 'complete';
const STEP_INTERACTION_CONTINUE_CLICK = 'continue click';
const STEP_INTERACTION_EXIT = 'exit';
const STEP_INTERACTION_BID_MODAL_DISPLAY = 'bid modal display';
const STEP_INTERACTION_LARGE_BID_WARNING_DISPLAY = 'large bid warning displayed';
const STEP_INTERACTION_MAX_BID_MODAL_DISPLAY = 'increase max bid modal displayed';
const STEP_INTERACTION_MAX_BID_MODAL_CLICK = 'increase max bid click';
const STEP_INTERACTION_CONFIRM_MAX_BID_MODAL_CLICK = 'confirm increase max bid click';
const STEP_INTERACTION_SHIPPING_LOCATION_UPDATE = 'shipping options location updated';
const STEP_INTERACTION_INTL_ZIP_CODE_ENTRY = 'intl zip code entry';
const STEP_INTERACTION_INTL_ZIP_CODE_COMPLETE = 'intl zip code complete';
const STEP_INTERACTION_PRODUCT = 'product';
const STEP_INTERACTION_OFFER_INQUIRY_ENTRY = 'offer inquiry entry';
const STEP_PRICING_GUIDANCE_DISPLAYED = 'pricing guidance displayed';
const STEP_PRICING_GUIDANCE_VIEW_MORE_CLICKED = 'pricing guidance view more click';
const STEP_ITEM_LISTING_PUBLISH_COMPLETE = 'item listing publish complete';
const STEP_INTERACTION_CHECKOUT_DEADEND = 'deadend error';
const STEP_INTERACTION_SHIPPING_COUNTRY_CHANGED = 'shipping country changed';
const STEP_INTERACTION_SHIPPING_METHOD_SAVED = 'shipping method saved';
const STEP_INTERACTION_SHIPPING_METHOD_VIEWED = 'shipping method viewed';

const STEP_INTERACTION_NFT_FROM_PDP = 'nft from pdp';
const STEP_INTERACTION_NFT_CREATOR_FROM_PDP = 'nft creator from pdp';
const STEP_INTERACTION_PRODUCT_FROM_PDP = 'product from pdp';
const STEP_INTERACTION_PRODUCT_FROM_DESIGN_PROFILE = 'product from design profile';
const STEP_INTERACTION_MAKE_OFFER = 'make offer click';
const STEP_INTERACTION_EMAIL_MAKE_OFFER = 'make offer click';
const STEP_INTERACTION_CONTINUE_MAKE_OFFER = 'make offer continue offer click';
const STEP_INTERACTION_ACCEPT_OFFER = 'accept offer click';
const STEP_INTERACTION_COUNTER_OFFER = 'counter offer click';
const STEP_INTERACTION_ADD_TO_CART = 'purchase click';
const STEP_INTERACTION_SMS_OPTIN = 'sms opt-in displayed - v2';
const STEP_INTERACTION_APP_DOWNLOAD_DISPLAYED = 'app download displayed';
const STEP_INTERACTION_APP_DOWNLOAD_SUBMITTED = 'app download submitted';
const STEP_INTERACTION_ADD_AUCTION_EVENT_TO_CALENDAR_CLICK = 'add to calendar click';
const STEP_INTERACTION_ADD_AUCTION_EVENT_TO_CALENDAR_DISPLAY = 'add to calendar displayed';
const STEP_INTERACTION_VIEW_MOVEMENT_DESCRIPTION_CLICK = 'view movement descriptions click';
const STEP_INTERACTION_VIEW_RING_SIZE_CLICK = 'view ring size click';
const STEP_INTERACTION_RARE_FIND_PILL_CLICK = 'rare find pill click';

const STEP_INTERACTION_ADDITIONAL_IMAGES_SUGGESTION_CLICK = 'additional_images click';
const STEP_INTERACTION_SHIPPING_SUGGESTION_CLICK = 'shipping click';
const STEP_INTERACTION_ITEM_DETAILS_SUGGESTION_CLICK = 'item_details click';
const STEP_INTERACTION_REQUEST_CUSTOMIZATION_SUGGESTION_CLICK = 'request_customization click';
const STEP_INTERACTION_NEGOTIATE_PRICE_SUGGESTION_CLICK = 'negotiate_price click';
const STEP_INTERACTION_ADDITIONAL_IMAGES_SUGGESTION_COMPLETE = 'additional_images complete';
const STEP_INTERACTION_SHIPPING_SUGGESTION_COMPLETE = 'shipping complete';
const STEP_INTERACTION_ITEM_DETAILS_SUGGESTION_COMPLETE = 'item_details complete';
const STEP_INTERACTION_REQUEST_CUSTOMIZATION_SUGGESTION_COMPLETE = 'request_customization complete';
const STEP_INTERACTION_NEGOTIATE_PRICE_SUGGESTION_COMPLETE = 'negotiate_price complete';
const STEP_INTERACTION_AUCTION_YOU_WON_DISPLAYED = 'auction you won displayed';
const STEP_INTERACTION_AUCTION_YOU_WON_CLICKED = 'auction you won clicked';
const STEP_INTERACTION_PDP_TAB_DISPLAY = 'pdp tab display';
const STEP_INTERACTION_PDP_TAB_CLICKED = 'pdp tab clicked';
const STEP_INTERACTION_VIEW_MORE_DETAILS_CLICK = 'view more details click';
const STEP_INTERACTION_VIEW_LESS_DETAILS_CLICK = 'view less details click';
const STEP_INTERACTION_ABANDON_CHECKOUT_RETURN_TO_ITEM = 'return to item click';
const STEP_INTERACTION_ABANDON_CHECKOUT_KEEP_CHECKING_OUT = 'keep checking out click';
const STEP_INTERACTION_ABANDON_CHECKOUT_BROWSER_MODAL = 'abandon browser modal display';
const STEP_SIMILAR_SOLD_ITEMS = 'similar sold items';
const STEP_INTERACTION_PRIMARY_IMAGE_UPLOAD = 'primary_image_uploaded';
const STEP_INTERACTION_PRIMARY_IMAGE_CHANGED = 'primary_image_changed';
const STEP_INTERACTION_PROCESSED_IMAGE_ACCEPTED = 'image_accepted';
const STEP_INTERACTION_PROCESSED_IMAGE_REJECTED = 'manual_review_requested';
const STEP_INTERACTION_SUBMIT_BUTTON_READY = 'submit button ready';
const STEP_INTERACTION_SOCIAL_BUTTON_CLICK = 'social button click';
const STEP_INTERACTION_EXISTING_SOCIAL_ACCOUNT_CONNECTED = 'existing account connected';
const STEP_INTERACTION_NEW_SOCIAL_ACCOUNT_CONNECTED = 'new account connected';
const STEP_INTERACTION_REGISTRATION_FORM_INTERACTION = 'registration form interaction';
const STEP_INTERACTION_TRADE_APPLICATION_FORM_INTERACTION = 'trade application form interaction';
const STEP_INTERACTION_TRADE_APPLICATION_FORM_ERROR = 'trade application form error';
const STEP_INTERACTION_GLOBAL_NAV_DROPDOWN_DISPLAYED = 'global nav dropdown displayed';
const STEP_INTERACTION_GLOBAL_NAV_DROPDOWN_CLICK = 'global nav dropdown click';
const STEP_INTERACTION_GLOBAL_NAV_LOGOUT_CLICK = 'global nav logout click';
const STEP_INTERACTION_GLOBAL_NAV_ACC_INFORMATION_CLICK = 'global nav account information click';
const STEP_INTERACTION_GLOBAL_NAV_TRADE_ACC_INFORMATION_CLICK =
    'global nav trade account information click';
const STEP_INTERACTION_GLOBAL_NAV_CONVERSATIONS_CLICK = 'global nav conversations click';
const STEP_INTERACTION_GLOBAL_NAV_TRADE_ORDERS_CLICK = 'global nav trade orders click';
const STEP_INTERACTION_GLOBAL_NAV_TRADE_OFFERS_CLICK = 'global nav trade offers click';
const STEP_INTERACTION_GLOBAL_NAV_TRADE_FIRM_CLICK = 'global nav trade firm click';
const STEP_INTERACTION_GLOBAL_NAV_TRADE_REWARDS_CLICK = 'global nav trade rewards click';
const STEP_INTERACTION_GLOBAL_NAV_TRADE_CONVERSATIONS_CLICK =
    'global nav trade conversations click';
const STEP_INTERACTION_GLOBAL_NAV_ORDERS_CLICK = 'global nav orders click';
const STEP_INTERACTION_GLOBAL_NAV_NFT_COLLECTOR_CLICK = 'global nav nft collector click';
const STEP_INTERACTION_GLOBAL_NAV_USER_ICON_CLICK = 'global nav user icon click';
const STEP_INTERACTION_GLOBAL_NAV_TRADE_LOGOUT_CLICK = 'global nav trade logout click';
const STEP_INTERACTION_GLOBAL_NAV_CART_CLICK = 'global nav cart click';
const STEP_INTERACTION_GLOBAL_NAV_CART_DROPDOWN_CLICK = 'global nav cart dropdown click';
const STEP_INTERACTION_GLOBAL_NAV_LIKES_ICON_CLICK = 'global nav likes icon click';
const STEP_INTERACTION_GLOBAL_NAV_MOBILE_DRILLDOWN_CLICK = 'global nav mobile drilldown click';
const STEP_INTERACTION_GLOBAL_NAV_MOBILE_SEARCH_BAR_FOCUS_CLICK =
    'global nav mobile searchbar focus click';
const STEP_INTERACTION_GLOBAL_NAV_MOBILE_SEARCH_BAR_CLICK = 'global nav mobile searchbar click';
const STEP_INTERACTION_GLOBAL_NAV_NOTIFICATIONS_CLICK = 'header notifications click';
const STEP_INTERACTION_GLOBAL_NAV_FAVORITES_NOTIFICATIONS_CLICK =
    'header favorites notification click';
const STEP_INTERACTION_GLOBAL_NAV_FAVORITES_NOTIFICATIONS_ALERT_DISPLAY =
    'header favorites notification alert display';
const STEP_INTERACTION_GLOBAL_NAV_FAVORITES_NOTIFICATIONS_DISPLAY =
    'header favorites notification display';
const STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_CLICK = 'global nav searchbar click';
const STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_ROW_CLICK = 'global nav searchbar row click';
const STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_SUBMIT_CLICK = 'global nav searchbar submit click';
const STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_SEARCH_SUGGESTION_DISPLAY =
    'global nav searchbar search suggestion display';
const STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_RECENT_SEARCHES_DISPLAY =
    'global nav searchbar recent searches display';
const STEP_INTERACTION_GLOBAL_NAV_LOGO_CLICK = 'global nav logo click';
const STEP_INTERACTION_GLOBAL_MESSAGE_BANNER_DISPLAYED = 'message banner displayed';
const STEP_INTERACTION_EMAIL_A_FRIEND = 'email a friend initiated';
const STEP_INTERACTION_EMAIL_A_FRIEND_SENT = 'email a friend sent';
const STEP_INTERACTION_SHIPPING_OPTIONS_MODAL = 'shipping options modal displayed';
const STEP_INTERACTION_FACET_EXPANDED = 'facet expanded';
const STEP_INTERACTION_FACET_COLLAPSED = 'facet collapsed';
const STEP_INTERACTION_PDP_IMAGE_CAROUSEL = 'pdp image carousel';
const STEP_INTERACTION_PRIMARY_IMAGE_CLICK = 'primary image click';
const STEP_INTERACTION_IMAGE_ZOOM = 'image zoom';
const STEP_INTERACTION_IMAGE_ZOOM_MODAL = 'image zoom modal';
const STEP_INTERACTION_PRINT_CLICK = 'print click';
const STEP_INTERACTION_RECOMMENDATION_VIEW_ALL_CLICK = 'view all clicked';

const STEP_INTERACTION_GLOBAL_MESSAGE_BANNER_CLICK = 'message banner click';
const STEP_INTERACTION_GLOBAL_MESSAGE_BANNER_CLOSE = 'message banner close';
const STEP_INTERACTION_PDP_CHAT_BOX_DISPLAY = 'pdp chat box displayed';
const STEP_INTERACTION_HOLD_REQUEST_SUBMIT = 'hold request submit';
const STEP_INTERACTION_HOLD_REQUEST_CLICK = 'hold request click';
const STEP_INTERACTION_SHIPPING_QUOTE_CLICK = 'shipping quote click';
const STEP_INTERACTION_SHIPPING_QUOTE_SUBMIT = 'shipping quote submit';
const STEP_INTERACTION_CONTACT_DEALER_ENTRY = 'contact dealer entry';
const STEP_INTERACTION_MODAL_DISPLAY = 'modal displayed';
const STEP_INTERACTION_CONTACT_DEALER_SEND_CLICK = 'contact dealer send click';
const STEP_INTERACTION_PDP_THUMBNAIL_CLICK = 'pdp thumbnail click';
const STEP_INTERACTION_CONTACT_DEALER_MODAL_CLICK = 'contact dealer modal click';
const STEP_INTERACTION_CONTACT_DEALER_CONFIRMATION_MODAL_DISPLAY =
    'contact dealer confirmation modal display';
const STEP_INTERACTION_CONTACT_DEALER_CONFIRMATION_MODAL_CLICK =
    'contact dealer confirmation modal click';
const STEP_INTERACTION_CONTACT_DEALER_GET_HELP_CLICK = 'get help contact seller click';
const STEP_INTERACTION_CONTACT_DEALER_SUBMIT = 'contact dealer submitted';
const STEP_INTERACTION_PDP_MODULE_ARROW_CLICK = 'pdp module arrow click';
const STEP_INTERACTION_USER_SURVEY_BUTTON_CLICK = 'user survey button click';
const STEP_INTERACTION_AUTO_MODAL_DISPLAYED = 'auto modal displayed';

const STEP_INTERACTION_ACCOUNT_INFORMATION = 'account information';
const STEP_INTERACTION_UPDATE_PASSWORD_MODAL = 'update password modal';
const STEP_INTERACTION_PASSWORD_RESET_MODAL_ENTRY = 'password reset modal entry';
const STEP_INTERACTION_PASSWORD_RESET_MODAL = 'password reset modal';
const STEP_INTERACTION_SEND_MESSAGE_CLICK = 'send message click';
const STEP_INTERACTION_REASON_SELECTED = 'reason selected';
const STEP_INTERACTION_PROMPT_DISPLAYED = 'prompt displayed';
const STEP_INTERACTION_BUTTON_CLICKED = 'button clicked';
const STEP_INTERACTION_LINK_CLICK = 'link click';
const STEP_INTERACTION_CANCELATION_STARTED = 'cancellation started';
const STEP_INTERACTION_CANCELATION_STOPPED = 'stop cancellation';
const STEP_INTERACTION_CANCELATION_CONFIRMED = 'cancellation confirmed';
const STEP_INTERACTION_CANCELATION_ERROR = 'cancellation error';
const STEP_INTERACTION_VIEW_INVOICE_CLICK = 'view invoice click';
const STEP_INTERACTION_PDP_STICKY_HEADER_DISPLAY = 'pdp sticky header display';

const METHOD_TYPE_PINTEREST = 'Pinterest';
const METHOD_TYPE_FACEBOOK = 'Facebook';
const METHOD_TYPE_WEBSITE = 'Website';
const METHOD_TYPE_INSTAGRAM = 'Instagram';
const METHOD_TYPE_TWITTER = 'Twitter';
const METHOD_TYPE_DISCORD = 'Discord';
const METHOD_TYPE_WIKIPEDIA = 'Wikipedia';

export const eventNameConstants = {
    EVENT_DWELL_TIME,
    EVENT_AB_TESTING,
    EVENT_AUCTION_BID,
    EVENT_PAYMENT_INFO,
    EVENT_WISHLIST,
    EVENT_SELECT_ITEM,
    EVENT_VIEW_ITEM,
    EVENT_VIEW_ITEM_LIST,
    EVENT_CONTACT_DEALER,
    EVENT_VIEW_PROMOTION,
    EVENT_SELECT_PROMOTION,
    EVENT_SIGN_UP,
    EVENT_LOGIN,
    EVENT_SHARE,
    EVENT_PURCHASE,
    EVENT_PRODUCT_INTERACTION,
    EVENT_ADD_CART,
    EVENT_SEARCH,
    EVENT_PROMO_INTERACTION,
    EVENT_RESULT_INTERACTION,
    EVENT_ITEM_UPLOAD_INTERACTION,
    EVENT_BULK_ITEM_UPLOAD,
    EVENT_ITEM_UPLOAD_COMPLETE,
    EVENT_DASHBOARD_INTERACTION,
    EVENT_LISTING_INTERACTION,
    EVENT_CONTACT_1STDIBS,
    EVENT_INTL_INTERACTION,
    EVENT_CHECKOUT_INTERACTION,
    EVENT_NAVIGATION,
    EVENT_BACKGROUND_REMOVAL,
    EVENT_SELLER_FACILITATED_SUPPORT,
    EVENT_BUYER_TAX_EXEMPTION,
    EVENT_INFO_INTERACTION,
    EVENT_SELLER_LEGACY,
    EVENT_RECOMMENDATIONS,
    EVENT_SHIPPING_METHOD_SAVED,
    EVENT_SHIPPING_METHOD_VIEWED,
    EVENT_MESSAGE_CENTER,
    EVENT_PRODUCT,
    EVENT_PRIVATE_OFFER,
    EVENT_MC_ACTIONS,
    EVENT_MC,
    EVENT_ORDER_INTERACTION,
    EVENT_RESULTS_PAGE_FACET_SELECTED,
    EVENT_RESULTS_PAGE_FACET_DESELECTED,
    EVENT_SELLER_APPLICATION,
    EVENT_DEALER_DASHBOARD,
    EVENT_DEALER_COLLECTIONS,
} as const;

export const triggerConstants = {
    TRIGGER_BID_CLICK,
    TRIGGER_BID_INCREASE,
    TRIGGER_PDP_PURCHASE,
    TRIGGER_MORE_FROM_DEALER,
    TRIGGER_CART_PURCHASE,
    TRIGGER_MY_ORDERS_ACCEPT_OFFER,
    TRIGGER_INVOICE_PURCHASE,
    TRIGGER_EMAIL_MAKE_OFFER,
    TRIGGER_EMAIL_ACCEPT_OFFER,
    TRIGGER_EMAIL_PURCHASE,
    TRIGGER_INCREASE_MAX_BID_CLICK,
    TRIGGER_ITEM_SAVE,
    TRIGGER_CART_ACCEPT_OFFER,
    TRIGGER_MESSAGE_CENTER,
    TRIGGER_SHIPPING_METHOD_CLICK,
} as const;

export const interactionTypeConstants = {
    INTERACTION_TYPE_DEALER_FAVORITING,
    INTERACTION_TYPE_ITEM_FAVORITING,
    INTERACTION_TYPE_ITEM_WATCH,
    INTERACTION_TYPE_AUCTION,
    INTERACTION_TYPE_CONTACT_DEALER,
    INTERACTION_TYPE_FACET_SELECTION,
    INTERACTION_TYPE_SHIPPING_QUOTE,
    INTERACTION_TYPE_PDP_CHAT_BOX,
    INTERACTION_TYPE_USER_LOGIN,
    INTERACTION_TYPE_USER_REGISTRATION,
    INTERACTION_TYPE_METAMASK_WALLET_CONNECT,
    INTERACTION_TYPE_NFT_AUCTION,
    INTERACTION_TYPE_MAKE_OFFER,
    INTERACTION_TYPE_ACCEPT_OFFER,
    INTERACTION_TYPE_COUNTER_OFFER,
    INTERACTION_TYPE_ADD_TO_CART,
    INTERACTION_TYPE_TRADE_APPLICATION,
    INTERACTION_TYPE_DEALER_APPLICATION,
    INTERACTION_TYPE_INTERNATIONAL,
    INTERACTION_TYPE_WATCH_ATTRIBUTE,
    INTERACTION_TYPE_PDP_SIDEBAR,
    INTERACTION_TYPE_PDP_CHAT_BOX_SUGGESTION,
    INTERACTION_TYPE_PRICING_GUIDANCE_DISPLAYED,
    INTERACTION_TYPE_LISTING_SAVED,
    INTERACTION_TYPE_PDP,
    INTERACTION_TYPE_PDP_ATTRIBUTE,
    INTERACTION_TYPE_CALL_DEALER,
    INTERACTION_TYPE_CHECKOUT_SIDERAIL_INTERACTION,
    INTERACTION_TYPE_CHECKOUT_ABANDON_PAGE,
    INTERACTION_TYPE_CHECKOUT_ABANDONED,
    INTERACTION_TYPE_CHECKOUT,
    INTERACTION_TYPE_ITEM_UPLOAD_PRIMARY_IMAGE,
    INTERACTION_TYPE_INFO_CLICK,
    INTERACTION_TYPE_DASHBOARD,
    INTERACTION_TYPE_SOCIAL_CONNECTION,
    INTERACTION_TYPE_USER_PREFERENCE_SURVEY,
    INTERACTION_TYPE_USER_TYPE_SELECTION,
    INTERACTION_TYPE_GLOBAL_NAV,
    INTERACTION_TYPE_MESSAGE_BANNER,
    INTERACTION_TYPE_CONTACT_SUBMITTED,
    INTERACTION_TYPE_SHIPPING_OPTIONS,
    INTERACTION_RECOMMENDATION_VIEW_ALL_CLICKED,
    INTERACTION_TYPE_PDP_MORE_FROM_DEALER,
    INTERACTION_TYPE_SHIPPING,
    INTERACTION_TYPE_OBFUSCATED_TEXT_IN_MESSAGE,
    INTERACTION_TYPE_DEALER_INFO_IN_MESSAGE,
    INTERACTION_TYPE_MC,
    INTERACTION_TYPE_MARKETING_OPTIN,
    INTERACTION_TYPE_SMS_OPTIN,
    INTERACTION_TYPE_URGENCY,
    INTERACTION_TYPE_APP_DOWNLOAD,
    INTERACTION_TYPE_ORDER_CANCELATION,
    INTERACTION_TYPE_PRICE_MATCH,
    INTERACTION_TYPE_DESIGN_PROFILE,
    INTERACTION_TYPE_NOTIFICATIONS,
    INTERACTION_TYPE_ACTIONS_REQUIRED,
    INTERACTION_TYPE_ANALYTICS_MODULE,
    INTERACTION_TYPE_EVERGREEN_LINK,
    INTERACTION_TYPE_NEWS_MODULE,
    INTERACTION_TYPE_ORDER_FEEDBACK,
    INTERACTION_TYPE_SEE_MARKETING_CALENDAR,
    INTERACTION_TYPE_BANNER_P1,
    INTERACTION_TYPE_SELLER_DASHBOARD_OVERVIEW_BANNER,
    INTERACTION_TYPE_SELLER_DASHBOARD_VIEW_ALL_LISTINGS,
    INTERACTION_TYPE_MY_ACCOUNT,
    INTERACTION_TYPE_ORDER_SUMMARY_DRAWER,
    INTERACTION_TYPE_SUPPORT,
} as const;

export const stepInteractionConstants = {
    STEP_INTERACTION_DEALER_ADDED,
    STEP_INTERACTION_ITEM_ADDED,
    STEP_INTERACTION_ITEM_ADDED_TO_FOLDER,
    STEP_INTERACTION_ITEM_REMOVED,
    STEP_INTERACTION_ENTRY,
    STEP_INTERACTION_CONFIRM_BID_CLICK,
    STEP_INTERACTION_COMPLETE,
    STEP_INTERACTION_CONTINUE_CLICK,
    STEP_INTERACTION_EXIT,
    STEP_INTERACTION_BID_MODAL_DISPLAY,
    STEP_INTERACTION_LARGE_BID_WARNING_DISPLAY,
    STEP_INTERACTION_NFT_FROM_PDP,
    STEP_INTERACTION_NFT_CREATOR_FROM_PDP,
    STEP_INTERACTION_PRODUCT_FROM_PDP,
    STEP_INTERACTION_PRODUCT_FROM_DESIGN_PROFILE,
    STEP_INTERACTION_MAKE_OFFER,
    STEP_INTERACTION_EMAIL_MAKE_OFFER,
    STEP_INTERACTION_CONTINUE_MAKE_OFFER,
    STEP_INTERACTION_ACCEPT_OFFER,
    STEP_INTERACTION_COUNTER_OFFER,
    STEP_INTERACTION_ADD_TO_CART,
    STEP_INTERACTION_SMS_OPTIN,
    STEP_INTERACTION_APP_DOWNLOAD_DISPLAYED,
    STEP_INTERACTION_APP_DOWNLOAD_SUBMITTED,
    STEP_INTERACTION_MAX_BID_MODAL_DISPLAY,
    STEP_INTERACTION_MAX_BID_MODAL_CLICK,
    STEP_INTERACTION_CONFIRM_MAX_BID_MODAL_CLICK,
    STEP_INTERACTION_SHIPPING_LOCATION_UPDATE,
    STEP_INTERACTION_INTL_ZIP_CODE_ENTRY,
    STEP_INTERACTION_INTL_ZIP_CODE_COMPLETE,
    STEP_INTERACTION_ADD_AUCTION_EVENT_TO_CALENDAR_CLICK,
    STEP_INTERACTION_ADD_AUCTION_EVENT_TO_CALENDAR_DISPLAY,
    STEP_INTERACTION_VIEW_MOVEMENT_DESCRIPTION_CLICK,
    STEP_INTERACTION_VIEW_RING_SIZE_CLICK,
    STEP_INTERACTION_PRODUCT,
    STEP_INTERACTION_OFFER_INQUIRY_ENTRY,
    STEP_INTERACTION_RARE_FIND_PILL_CLICK,
    STEP_INTERACTION_ADDITIONAL_IMAGES_SUGGESTION_CLICK,
    STEP_INTERACTION_SHIPPING_SUGGESTION_CLICK,
    STEP_INTERACTION_ITEM_DETAILS_SUGGESTION_CLICK,
    STEP_INTERACTION_REQUEST_CUSTOMIZATION_SUGGESTION_CLICK,
    STEP_INTERACTION_NEGOTIATE_PRICE_SUGGESTION_CLICK,
    STEP_INTERACTION_ADDITIONAL_IMAGES_SUGGESTION_COMPLETE,
    STEP_INTERACTION_SHIPPING_SUGGESTION_COMPLETE,
    STEP_INTERACTION_ITEM_DETAILS_SUGGESTION_COMPLETE,
    STEP_INTERACTION_REQUEST_CUSTOMIZATION_SUGGESTION_COMPLETE,
    STEP_INTERACTION_NEGOTIATE_PRICE_SUGGESTION_COMPLETE,
    STEP_PRICING_GUIDANCE_DISPLAYED,
    STEP_PRICING_GUIDANCE_VIEW_MORE_CLICKED,
    STEP_ITEM_LISTING_PUBLISH_COMPLETE,
    STEP_INTERACTION_AUCTION_YOU_WON_DISPLAYED,
    STEP_INTERACTION_AUCTION_YOU_WON_CLICKED,
    STEP_INTERACTION_PDP_TAB_DISPLAY,
    STEP_INTERACTION_PDP_TAB_CLICKED,
    STEP_INTERACTION_VIEW_MORE_DETAILS_CLICK,
    STEP_INTERACTION_VIEW_LESS_DETAILS_CLICK,
    STEP_INTERACTION_ABANDON_CHECKOUT_RETURN_TO_ITEM,
    STEP_INTERACTION_ABANDON_CHECKOUT_KEEP_CHECKING_OUT,
    STEP_INTERACTION_ABANDON_CHECKOUT_BROWSER_MODAL,
    STEP_INTERACTION_CHECKOUT_DEADEND,
    STEP_INTERACTION_SHIPPING_COUNTRY_CHANGED,
    STEP_SIMILAR_SOLD_ITEMS,
    STEP_INTERACTION_PRIMARY_IMAGE_UPLOAD,
    STEP_INTERACTION_PRIMARY_IMAGE_CHANGED,
    STEP_INTERACTION_PROCESSED_IMAGE_ACCEPTED,
    STEP_INTERACTION_PROCESSED_IMAGE_REJECTED,
    STEP_INTERACTION_SUBMIT_BUTTON_READY,
    STEP_INTERACTION_SOCIAL_BUTTON_CLICK,
    STEP_INTERACTION_EXISTING_SOCIAL_ACCOUNT_CONNECTED,
    STEP_INTERACTION_NEW_SOCIAL_ACCOUNT_CONNECTED,
    STEP_INTERACTION_REGISTRATION_FORM_INTERACTION,
    STEP_INTERACTION_TRADE_APPLICATION_FORM_INTERACTION,
    STEP_INTERACTION_TRADE_APPLICATION_FORM_ERROR,
    STEP_INTERACTION_GLOBAL_NAV_DROPDOWN_DISPLAYED,
    STEP_INTERACTION_GLOBAL_NAV_DROPDOWN_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_LOGOUT_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_TRADE_LOGOUT_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_ACC_INFORMATION_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_CONVERSATIONS_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_TRADE_CONVERSATIONS_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_ORDERS_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_NFT_COLLECTOR_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_USER_ICON_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_TRADE_OFFERS_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_TRADE_ORDERS_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_TRADE_FIRM_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_TRADE_REWARDS_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_TRADE_ACC_INFORMATION_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_CART_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_CART_DROPDOWN_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_LIKES_ICON_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_MOBILE_DRILLDOWN_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_MOBILE_SEARCH_BAR_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_NOTIFICATIONS_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_ROW_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_SUBMIT_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_SEARCH_SUGGESTION_DISPLAY,
    STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_RECENT_SEARCHES_DISPLAY,
    STEP_INTERACTION_GLOBAL_NAV_FAVORITES_NOTIFICATIONS_DISPLAY,
    STEP_INTERACTION_GLOBAL_NAV_FAVORITES_NOTIFICATIONS_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_FAVORITES_NOTIFICATIONS_ALERT_DISPLAY,
    STEP_INTERACTION_GLOBAL_NAV_MOBILE_SEARCH_BAR_FOCUS_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_LOGO_CLICK,
    STEP_INTERACTION_GLOBAL_MESSAGE_BANNER_DISPLAYED,
    STEP_INTERACTION_GLOBAL_MESSAGE_BANNER_CLICK,
    STEP_INTERACTION_GLOBAL_MESSAGE_BANNER_CLOSE,
    STEP_INTERACTION_EMAIL_A_FRIEND,
    STEP_INTERACTION_EMAIL_A_FRIEND_SENT,
    STEP_INTERACTION_SHIPPING_OPTIONS_MODAL,
    STEP_INTERACTION_FACET_EXPANDED,
    STEP_INTERACTION_FACET_COLLAPSED,
    STEP_INTERACTION_PDP_IMAGE_CAROUSEL,
    STEP_INTERACTION_PRIMARY_IMAGE_CLICK,
    STEP_INTERACTION_IMAGE_ZOOM,
    STEP_INTERACTION_IMAGE_ZOOM_MODAL,
    STEP_INTERACTION_PRINT_CLICK,
    STEP_INTERACTION_RECOMMENDATION_VIEW_ALL_CLICK,
    STEP_INTERACTION_PDP_CHAT_BOX_DISPLAY,
    STEP_INTERACTION_HOLD_REQUEST_SUBMIT,
    STEP_INTERACTION_HOLD_REQUEST_CLICK,
    STEP_INTERACTION_SHIPPING_QUOTE_CLICK,
    STEP_INTERACTION_SHIPPING_QUOTE_SUBMIT,
    STEP_INTERACTION_CONTACT_DEALER_ENTRY,
    STEP_INTERACTION_MODAL_DISPLAY,
    STEP_INTERACTION_CONTACT_DEALER_SEND_CLICK,
    STEP_INTERACTION_PDP_THUMBNAIL_CLICK,
    STEP_INTERACTION_CONTACT_DEALER_MODAL_CLICK,
    STEP_INTERACTION_CONTACT_DEALER_CONFIRMATION_MODAL_CLICK,
    STEP_INTERACTION_CONTACT_DEALER_CONFIRMATION_MODAL_DISPLAY,
    STEP_INTERACTION_CONTACT_DEALER_GET_HELP_CLICK,
    STEP_INTERACTION_CONTACT_DEALER_SUBMIT,
    STEP_INTERACTION_ACCOUNT_INFORMATION,
    STEP_INTERACTION_UPDATE_PASSWORD_MODAL,
    STEP_INTERACTION_PASSWORD_RESET_MODAL_ENTRY,
    STEP_INTERACTION_PASSWORD_RESET_MODAL,
    STEP_INTERACTION_SHIPPING_METHOD_SAVED,
    STEP_INTERACTION_SHIPPING_METHOD_VIEWED,
    STEP_INTERACTION_ITEM_FOLDER_CLICKED,
    STEP_INTERACTION_ITEM_REMOVED_FROM_FOLDER,
    STEP_INTERACTION_ITEM_FOLDER_CREATED,
    STEP_INTERACTION_SEND_MESSAGE_CLICK,
    STEP_INTERACTION_PDP_MODULE_ARROW_CLICK,
    STEP_INTERACTION_USER_SURVEY_BUTTON_CLICK,
    STEP_INTERACTION_DISPLAY,
    STEP_INTERACTION_CLICK,
    STEP_INTERACTION_SUCCESS,
    STEP_INTERACTION_FAIL,
    STEP_INTERACTION_EXPAND,
    STEP_INTERACTION_COLLAPSE,
    STEP_INTERACTION_AUTO_MODAL_DISPLAYED,
    STEP_INTERACTION_REASON_SELECTED,
    STEP_INTERACTION_PROMPT_DISPLAYED,
    STEP_INTERACTION_BUTTON_CLICKED,
    STEP_INTERACTION_LINK_CLICK,
    STEP_INTERACTION_CANCELATION_STARTED,
    STEP_INTERACTION_CANCELATION_STOPPED,
    STEP_INTERACTION_CANCELATION_CONFIRMED,
    STEP_INTERACTION_CANCELATION_ERROR,
    STEP_INTERACTION_VIEW_INVOICE_CLICK,
    STEP_INTERACTION_PDP_STICKY_HEADER_DISPLAY,
} as const;

export const methodTypeConstants = {
    METHOD_TYPE_PINTEREST,
    METHOD_TYPE_FACEBOOK,
    METHOD_TYPE_WEBSITE,
    METHOD_TYPE_INSTAGRAM,
    METHOD_TYPE_TWITTER,
    METHOD_TYPE_DISCORD,
    METHOD_TYPE_WIKIPEDIA,
} as const;
