export const intentTypes = {
    CONSUMER: 'CONSUMER',
    DEALER: 'DEALER',
    TRADE: 'TRADE',
} as const;
export type IntentValues = (typeof intentTypes)[keyof typeof intentTypes];

export const stepLabels = {
    DISPLAYED: 'displayed',
    COMPLETED: 'completed',
    SKIPPED: 'skipped',
} as const;

export const answerNames = {
    GIFT: 'GIFT',
} as const;

export const steps = {
    USER_TYPE_SELECTION: 'user_type_selection',
    CONSUMER_BENEFITS: 'consumer_benefits',
    CONSUMER_COMPLETE: 'consumer_complete',
    CONSUMER_INFO: 'consumer_info',
    DEALER_INFO: 'dealer_info',
    TRADE_APPLICATION_STEP_1: 'trade_application_step_1',
    TRADE_APPLICATION_STEP_1_WHITELISTED: 'trade_application_step_1_whitelisted',
    TRADE_APPLICATION_STEP_2: 'trade_application_step_2',
    TRADE_APPLICATION_MORE_INFO: 'trade_application_more_info',
    TRADE_REFERRAL: 'trade_referral',
    TRADE_COMPLETE: 'trade_complete',
    TRADE_COMPLETE_WHITELISTED: 'trade_complete_whitelisted',
    PROMO_CODE: 'promo_code',
    USER_SURVEY: 'user_survey',
    USER_SURVEY_CONFIRMATION: 'user_survey_confirmation',
    USER_SURVEY_GIFT_CONFIRMATION: 'user_survey_gift_confirmation',
    MARKETING_OPT_IN: 'marketing_opt_in',
} as const;

export const gaLabels = {
    PAGEVIEW_LIMIT_REACHED: 'pageview limit reached',
    LABEL_DWELL_TIME_REACHED: 'dwell time reached',
} as const;

export const events = {
    USER_CHANGED: 'user_changed',
} as const;
export type Events = keyof typeof events;
export type EventValues = (typeof events)[Events];

const registrationLeadSourceLabel = 'Web: User Registration' as const;
const loginLeadSourceLabel = 'Web: User Login' as const;
const socialRegistrationSourceLabel = 'Web: Social Registration' as const;

const socialRegisterSourceLabel = {
    leadSourceLabel: socialRegistrationSourceLabel,
    tradeLeadSourceLabel: socialRegistrationSourceLabel,
};

const socialRegisterUserTypeSelection = {
    ...socialRegisterSourceLabel,
    startingStep: steps.USER_TYPE_SELECTION,
};

const socialRegisterMarketingOptIn = {
    ...socialRegisterSourceLabel,
    startingStep: steps.MARKETING_OPT_IN,
};

const socialRegisterUserSurvey = {
    ...socialRegisterSourceLabel,
    startingStep: steps.USER_SURVEY,
};

export const flowActions = {
    AUTO_PROMPT_REGISTER: {
        leadSourceLabel: registrationLeadSourceLabel,
        startingStep: steps.USER_TYPE_SELECTION,
    },
    LOGIN: {
        leadSourceLabel: loginLeadSourceLabel,
        startingStep: steps.USER_TYPE_SELECTION,
    },
    REGISTER: {
        leadSourceLabel: registrationLeadSourceLabel,
        startingStep: steps.USER_TYPE_SELECTION,
    },
    SOCIAL_REGISTER_FACEBOOK: socialRegisterUserTypeSelection,
    SOCIAL_REGISTER_GOOGLE: socialRegisterUserTypeSelection,
    SOCIAL_REGISTER_APPLE: socialRegisterUserTypeSelection,
    SOCIAL_REGISTER_FACEBOOK_MARKETING: socialRegisterMarketingOptIn,
    SOCIAL_REGISTER_GOOGLE_MARKETING: socialRegisterMarketingOptIn,
    SOCIAL_REGISTER_APPLE_MARKETING: socialRegisterMarketingOptIn,
    SOCIAL_REGISTER_FACEBOOK_USER_SURVEY: socialRegisterUserSurvey,
    SOCIAL_REGISTER_GOOGLE_USER_SURVEY: socialRegisterUserSurvey,
    SOCIAL_REGISTER_APPLE_USER_SURVEY: socialRegisterUserSurvey,
    SOCIAL_LOGIN_MARKETING: socialRegisterMarketingOptIn,
    TRADE_APPLICATION: {
        leadSourceLabel: 'Web: Trade Registration',
        startingStep: steps.TRADE_APPLICATION_STEP_1,
    },
    TRADE_APPLICATION_STEP_2: {
        leadSourceLabel: 'Web: Trade Registration',
        startingStep: steps.TRADE_APPLICATION_STEP_2,
    },
    SUSPECTED_TRADE_APPLICATION: {
        leadSourceLabel: 'Web: Trade Registration',
        startingStep: steps.TRADE_APPLICATION_STEP_1,
    },
    SUSPECTED_TRADE_APPLICATION_PASSWORD: {
        leadSourceLabel: 'Web: Trade Registration',
        startingStep: steps.TRADE_APPLICATION_STEP_1,
    },
    TRADE_APPLICATION_MORE_INFO: {
        leadSourceLabel: 'Web: Trade Registration',
        startingStep: steps.TRADE_APPLICATION_MORE_INFO,
    },
    ALT_REGISTRATION_CONSUMER_FLOW: {
        leadSourceLabel: registrationLeadSourceLabel,
        startingStep: steps.USER_SURVEY,
    },
} as const;
export type FlowActions = keyof typeof flowActions;
