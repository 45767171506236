import { fetchQuery_DEPRECATED as fetchQuery } from 'relay-runtime';
import { graphql } from 'react-relay/legacy';
import { getBuyerId } from 'dibs-cookie-jar';
import { getUserSessionCountryCode } from 'dibs-regional-info/exports/regionalInfoHelpers';
import createEnvironment from '../relayClientEnvironment';
import { init } from './userTracking';

export const trackingQuery = graphql`
    query initializeTrackingQuery(
        $hasUserId: Boolean!
        $userId: String!
        $fetchTrackingGdprInfo: Boolean = true
        $userCountryCode: String = ""
    ) {
        viewer {
            ...userTracking_viewer @relay(mask: false)
            user(userId: $userId) @include(if: $hasUserId) {
                ...userTracking_user @relay(mask: false)
            }
        }
    }
`;

export default async function initializeTracking(environment) {
    environment = environment || createEnvironment();
    const userId = getBuyerId(document.cookie) || '';
    const hasUserId = !!userId;
    const { viewer } =
        (await fetchQuery(environment, trackingQuery, {
            userId,
            hasUserId,
            userCountryCode: getUserSessionCountryCode(),
        })) || {};
    init({ viewer, user: viewer?.user, environment });
}
