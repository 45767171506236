import { RequestParameters, GraphQLResponseWithData, PayloadError } from 'relay-runtime';

export class DibsRelayNetworkError extends Error {
    public traceGuid?: string;
    public source?: {
        errors?: PayloadError[] | null;
    } | null;
}

export function createNetworkError(
    operation: RequestParameters,
    json: GraphQLResponseWithData,
    responseHeaders?: Headers
): DibsRelayNetworkError {
    const error: DibsRelayNetworkError = new Error('DibsRelayNetworkLayer');
    const errorsMessage = json.errors
        ? json.errors.map(({ message }) => message).join('\n')
        : '(No errors)';

    error.message = `No data returned for operation '${operation.name}' got error(s):\n${errorsMessage}\n\nSee the error 'source' property for more information.`;

    const traceGuid = responseHeaders?.get('x-dibs-trace-guid');
    if (traceGuid) {
        error.traceGuid = traceGuid;
    }

    error.source = {
        errors: json.errors,
    };

    return error;
}
