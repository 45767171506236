import { trackEvent } from './trackEvent';
import { eventNameConstants } from './constants';

export const PAGE_DWELL_TRACKER_STOP_TIMES = {
    homepage: {
        resp: 10,
        mobile: 12,
    },
    pdp: {
        resp: 22,
        mobile: 19,
    },
} as const;

/**
 * Class is exposed for TESTING ONLY!
 * Singleton object should be used
 */
export class PageDwellTracker {
    private elapsedTime: number = 0;
    private startTimestamp: number = 0;
    private stopSecond: number = 0;
    private timeoutId?: NodeJS.Timer;
    private hasStarted: boolean = false;

    constructor() {
        this.visibilityChangeHandler = this.visibilityChangeHandler.bind(this);
        this.startTracking = this.startTracking.bind(this);
        this.track = this.track.bind(this);
        this.stop = this.stop.bind(this);
    }

    private track(): void {
        const currentTimestamp = new Date().getTime();
        this.elapsedTime += currentTimestamp - this.startTimestamp;
        this.startTimestamp = currentTimestamp;

        // Get full second of dwell time
        const secondToTrack = Math.floor(this.elapsedTime / 1000);

        if (secondToTrack >= this.stopSecond) {
            const secondsElapsedTrackingStr = 'seconds elapsed';

            trackEvent({
                eventName: eventNameConstants.EVENT_DWELL_TIME,
                interaction_type: secondsElapsedTrackingStr,
                step_interaction_name: this.stopSecond,

                category: 'dwell time',
                action: secondsElapsedTrackingStr,
                // Track stopSecond, not actual, bc actual can be bigger due to event loop latency
                // Tracking stopSecond should ensure consistency is GA data
                label: this.stopSecond,
            });
            this.stopTracking();
        }
    }

    private startTracking(): void {
        // Order is important here. Track function should be able to clear this timeout if tracking has to be stopped
        this.timeoutId = setTimeout(this.startTracking, 500);
        this.track();
    }

    private stopTracking(): void {
        clearTimeout(this.timeoutId);
        document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
    }

    private visibilityChangeHandler(): void {
        if (document.hidden) {
            this.track();
            clearTimeout(this.timeoutId);
        } else {
            this.startTimestamp = new Date().getTime();
            this.startTracking();
        }
    }

    private reset(): void {
        clearTimeout(this.timeoutId);
        this.elapsedTime = 0;
        this.startTimestamp = new Date().getTime();
        if (!document.hidden) {
            this.startTracking();
        }
    }

    stop(): void {
        this.stopTracking();
        this.hasStarted = false;
    }

    start(stopSecond: number): void {
        if (this.hasStarted) {
            return;
        }
        this.stopSecond = stopSecond;
        this.reset();
        document.addEventListener('visibilitychange', this.visibilityChangeHandler);
        this.hasStarted = true;
    }

    resetIfStarted(): void {
        if (this.hasStarted) {
            this.reset();
        }
    }
}

export const pageDwellTracker = new PageDwellTracker();
