import { ReactNode } from 'react';

import { FormattedMessage, defineMessages } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';

import { PrivacyPolicyLink, UserAgreementLink } from '../shared/Legal';
import { EMAIL_MAX_LENGTH } from '../helpers/validators';

export const placeholders = defineMessages({
    passwordPlaceholder: {
        id: 'dbl.authmodal.auth.passwordPlaceholder',
        defaultMessage: 'Password',
    },
    emailPlaceholder: {
        id: 'dbl.authmodal.auth.emailPlaceholder',
        defaultMessage: 'Email address',
    },
    passwordConfPlaceholder: {
        id: 'dbl.authmodal.auth.passwordConfPlaceholder',
        defaultMessage: 'Re-enter new password',
    },
});

export default {
    acceptPolicyContactDealer: (
        <FormattedMessage
            id="dbl.authmodal.auth.acceptPolicyContactDealer"
            defaultMessage="I accept the 1stDibs’ {privacyPolicyLink} and {userAgreementLink}, and understand that sharing contact information to purchase outside of 1stDibs is prohibited."
            values={{
                privacyPolicyLink: <PrivacyPolicyLink />,
                userAgreementLink: <UserAgreementLink />,
            }}
        />
    ),
    accountLogin: (
        <FormattedMessage
            key="accountLogin"
            id="dbl.authmodal.auth.accountLogin"
            defaultMessage="Already have an account?"
        />
    ),
    continue: <FormattedMessage id="dbl.authmodal.auth.continue" defaultMessage="Continue" />,
    login: <FormattedMessage key="login" id="dbl.authmodal.auth.login" defaultMessage="Log in" />,
    register: <FormattedMessage id="dbl.authmodal.auth.register" defaultMessage="Sign up" />,
    submit: <FormattedMessage id="dbl.authmodal.auth.submit" defaultMessage="Submit" />,
    welcome: (
        <FormattedMessage id="dbl.authmodal.auth.welcome" defaultMessage="Welcome to 1stDibs" />
    ),
    changeFlow: (
        <FormattedMessage
            id="dbl.authmodal.login.accountRegistration"
            defaultMessage="Don't have an account?"
        />
    ),
    backToLogin: (
        <FormattedMessage
            id="dbl.authmodal.requestPasswordEmail.backToLogin"
            defaultMessage="< Back to login"
        />
    ),
    createPassword: {
        header: (
            <FormattedMessage
                id="dbl.authmodal.auth.createPassword.header"
                defaultMessage="Create Password"
            />
        ),
        viewConversationsHeader: (
            <FormattedMessage
                id="dbl.authmodal.auth.createPassword.viewConversationsHeader"
                defaultMessage="Create Password to View Conversations"
            />
        ),
        viewOrdersHeader: (
            <FormattedMessage
                id="dbl.authmodal.auth.createPassword.viewOrdersHeader"
                defaultMessage="Create Password to View Orders"
            />
        ),
        acceptOfferHeader: (
            <FormattedMessage
                id="dbl.authmodal.auth.createPassword.acceptOfferHeader"
                defaultMessage="Create an Account to View Your Offer"
            />
        ),
        requestShippingQuoteHeader: (
            <FormattedMessage
                id="dbl.authmodal.auth.createPassword.requestShippingQuoteHeader"
                defaultMessage="Create Password to Request Shipping Quote"
            />
        ),
        viewInvoiceHeader: (
            <FormattedMessage
                id="dbl.authmodal.auth.createPassword.viewInvoiceHeader"
                defaultMessage="Create Password to View Invoice"
            />
        ),
        callSellerHeader: (
            <FormattedMessage
                id="dbl.authmodal.auth.createPassword.callSellerHeader"
                defaultMessage="Create Password to Call the Seller"
            />
        ),
        sendMessageHeader: (
            <FormattedMessage
                id="dbl.authmodal.auth.createPassword.sendMessageHeader"
                defaultMessage="Create Password to Send Your Message"
            />
        ),
        bidHeader: (
            <FormattedMessage
                id="dbl.authmodal.auth.createPassword.bidHeader"
                defaultMessage="Create Password to Bid"
            />
        ),
        connectWallet: (
            <FormattedMessage
                id="dbl.authmodal.auth.createPassword.connectWallet"
                defaultMessage="Create Password to Connect Your Wallet"
            />
        ),
    },
    watchItem: {
        loginHeader: (
            <FormattedMessage
                id="dbl.authmodal.login.watchItem.loginHeader"
                defaultMessage="Log In to Watch This Item"
            />
        ),
        registrationHeader: (
            <FormattedMessage
                id="dbl.authmodal.registration.watchItem.registrationHeader"
                defaultMessage="Sign Up to Watch This Item"
            />
        ),
    },
    saveItem: {
        loginHeader: (
            <FormattedMessage
                id="dbl.authmodal.login.saveItem.loginHeader"
                defaultMessage="Log In to Save This Item"
            />
        ),
        registrationHeader: (
            <FormattedMessage
                id="dbl.authmodal.registration.saveItem.registrationHeader"
                defaultMessage="Sign Up to Save This Item"
            />
        ),
        auctionSubtitle: (
            <FormattedMessage
                id="dbl.authmodal.auth.saveItem.auctionSubtitle"
                defaultMessage="We’ll notify you on auction activity."
            />
        ),
        subtitle: (
            <FormattedMessage
                id="dbl.authmodal.auth.saveItem.subtitle"
                defaultMessage="We'll alert you when it goes on sale."
            />
        ),
        registrationCompletionHeader: (
            <FormattedMessage
                id="dbl.authmodal.registration.emailOnlySaveItemFlow.registrationCompletionHeader"
                defaultMessage="Your Item Has Been Saved"
            />
        ),
    },
    saveToFolder: {
        loginHeader: (
            <FormattedMessage
                id="dbl.authmodal.login.saveItem.loginHeaderFolder"
                defaultMessage="Log In to Save This Item to a Folder"
            />
        ),
        registrationHeader: (
            <FormattedMessage
                id="dbl.authmodal.registration.saveItem.registrationHeaderFolder"
                defaultMessage="Sign Up to Save This Item to a Folder"
            />
        ),
        registrationCompletionHeader: (
            <FormattedMessage
                id="dbl.authmodal.registration.emailOnlySaveItemToFolderFlow.registrationCompletionHeader"
                defaultMessage="Your Item Has Been Saved to a Folder"
            />
        ),
    },
    trade: {
        welcome: (
            <FormattedMessage
                id="dbl.authmodal.auth.trade.welcome"
                defaultMessage="Complete Your Profile"
            />
        ),
        header: (
            <FormattedMessage
                id="dbl.authmodal.auth.trade.header"
                defaultMessage="Complimentary Trade-Only Benefits"
            />
        ),
        registrationCompletionHeader: (
            <FormattedMessage
                id="dbl.authmodal.registration.tradeIntentCompletionHeader"
                defaultMessage="Create An Account to Continue"
            />
        ),
        registrationCompletionSubheader: (
            <FormattedMessage
                id="dbl.authmodal.registration.tradeIntentCompletionSubheader"
                defaultMessage="A password is required to create an account. You’ll then have full access to 1stDibs and receive personalized email updates."
            />
        ),
        registrationDescription: (
            <FormattedMessage
                id="dbl.authmodal.registration.tradeIntentSubtitle"
                defaultMessage="Enjoy net price discounts, personalized support and more when you join the Trade 1st program."
            />
        ),
    },
    tradeLogin: {
        header: (
            <FormattedMessage
                id="dbl.authmodal.auth.tradeLogin.header"
                defaultMessage="Log In for Trade-Only Prices"
            />
        ),
        subHeader: (
            <FormattedMessage
                id="dbl.authmodal.auth.tradeLogin.subHeader"
                defaultMessage="View net prices up to 30% off and access all your other benefits."
            />
        ),
    },
    errors: {
        conflictRegistration: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.conflictRegistration"
                defaultMessage="This email address has already been registered. Please enter your password to login."
            />
        ),
        emailInvalid: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.emailInvalid"
                defaultMessage="Please enter a valid email address."
            />
        ),
        requestPasswordEmailInvalid: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.requestPasswordEmailInvalid"
                defaultMessage="Invalid email address. Please try again."
            />
        ),
        emailMax: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.emailMax"
                defaultMessage="Email may only contain no more than {maxLength} characters."
                values={{
                    maxLength: EMAIL_MAX_LENGTH,
                }}
            />
        ),
        emailRequired: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.emailRequired"
                defaultMessage="Email address is required."
            />
        ),
        invalidLogin: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.invalidLogin"
                defaultMessage="The email/password combination does not match our records. Passwords are case sensitive."
            />
        ),
        invalidResetPasswordWithResetSuggestion: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.invalidResetPasswordWithResetSuggestion"
                defaultMessage="The email/password combination does not match our records. Passwords are case sensitive. Having trouble? <a>Reset your password</a>."
                values={{
                    a: content => (
                        <Link
                            onClick={e => {
                                e.preventDefault();
                                authModalLoader.show({
                                    action: authModalLoader.constants.REQUEST_PASSWORD_EMAIL,
                                    flow: authModalLoader.constants.RESET_PASSWORD_EMAIL_FLOW,
                                });
                            }}
                        >
                            {content}
                        </Link>
                    ),
                }}
            />
        ),
        credentialsLeaked: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.credentialsLeaked"
                defaultMessage="This username and password combination is suspected to be compromised. Please create a new password."
            />
        ),
        credentialsLeakedWithLink: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.credentialsLeakedWithLink"
                defaultMessage="This username and password combination is suspected to be compromised. Please <a>create a new password</a>."
                values={{
                    a: content => (
                        <Link
                            onClick={e => {
                                e.preventDefault();
                                authModalLoader.show({
                                    action: authModalLoader.constants.REQUEST_PASSWORD_EMAIL,
                                    flow: authModalLoader.constants.RESET_PASSWORD_EMAIL_FLOW,
                                });
                            }}
                        >
                            {content}
                        </Link>
                    ),
                }}
            />
        ),
        suspiciousActivity: (
            <>
                <FormattedMessage
                    id="dbl.authmodal.auth.errors.suspiciousActivity"
                    defaultMessage="We've detected suspicious login activity on this device. Please disable your browser's private browsing mode or any VPNs before you try again."
                />
                <br />
                <br />
                <FormattedMessage
                    id="dbl.authmodal.auth.errors.suspiciousActivityLine2"
                    defaultMessage="If you continue to see this error, please contact the <a>support team</a>."
                    values={{
                        a: content => (
                            <Link
                                href="/contact/?category=account&topic=accountSecurity"
                                target="_blank"
                            >
                                {content}
                            </Link>
                        ),
                    }}
                />
            </>
        ),
        invalidResetToken: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.invalidResetToken"
                defaultMessage="The link to reset your password is invalid. Please make sure that:<ul><li>The link was requested within the last 24 hours</li><li>The link has not been used before to reset your password</li><li>The link is spelled correctly</li><li>If a reset was requested multiple times, this is the most recently sent link</li></ul>"
                values={{ ul: text => <ul>{text}</ul>, li: text => <li>{text}</li> }}
            />
        ),
        passwordMatch: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.passwordMatch"
                defaultMessage="The passwords do not match. Please check the passwords that you entered."
            />
        ),
        passwordRequired: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.passwordRequired"
                defaultMessage="Password is required."
            />
        ),
        passwordShort: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.passwordShort"
                defaultMessage="Please enter a minimum of 5 characters."
            />
        ),
        googleOrigin: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.origin"
                defaultMessage="This subdomain is not whitelisted in the Google SSO console. Please contact sysops."
            />
        ),
        support: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.support"
                defaultMessage="Authentication error! Please contact {mailLink}"
                values={{
                    mailLink: <Link href="mailto:support@1stdibs.com">support@1stdibs.com</Link>,
                }}
            />
        ),
        facebookLoginOnly: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.facebookLoginOnly"
                defaultMessage="You've registered through Facebook. Please continue with Facebook Sign-in instead."
            />
        ),
        googleLoginOnly: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.googleLoginOnly"
                defaultMessage="You've registered through Google. Please continue with Google Sign-in instead."
            />
        ),
        appleLoginOnly: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.appleLoginOnly"
                defaultMessage="You've registered with Apple. Please continue with Apple Sign-in instead."
            />
        ),
        socialNetworkSdkLoadingFailed: (message: string): ReactNode => {
            const socialNetwork = (message.split(' ')[0] || '').toLowerCase();
            const socialNetworkName =
                socialNetwork.charAt(0).toUpperCase() + socialNetwork.slice(1);

            return (
                <FormattedMessage
                    id="dbl.authmodal.auth.errors.socialNetworkSdkLoadingFailed"
                    defaultMessage="{socialNetworkName} sign-on is not available at this time. Please try another sign-on method or try again later."
                    values={{
                        socialNetworkName,
                    }}
                />
            );
        },
        socialLoginNoEmailReturned: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.socialLoginNoEmailReturned"
                defaultMessage="Your Facebook account needs a valid email to continue. Please try again with an alternative Facebook login."
            />
        ),
        emailOnlyCreatePassword: (
            <FormattedMessage
                id="dbl.authmodal.auth.errors.emailOnlyCreatePassword"
                defaultMessage="Please click on the link below to create a password for this account."
            />
        ),
    },
    designerConnectionModal: {
        subText: (
            <FormattedMessage
                id="dbl.authmodal.auth.designerConnectionModal.subText"
                defaultMessage="We'll start matching you with award-winning designers"
            />
        ),
    },
};
